import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayerIcon = _resolveComponent("PlayerIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AddonIcon = _resolveComponent("AddonIcon")!

  return (_ctx.targetIsRole(_ctx.target))
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: _normalizeClass(["preview-link", _ctx.rolesShortInfo[_ctx.target].loyalty + '-role']),
        to: { name: _ctx.toSnakeCase(_ctx.normalizeRoleRoute) }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PlayerIcon, {
            class: "icon-in-link",
            icon: _ctx.target
          }, null, 8, ["icon"]),
          (_ctx.text !== '')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.text ? _ctx.$t('previewLink.' + _ctx.text) : _ctx.$t('roles.' + _ctx.target)), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class", "to"]))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        class: "preview-link addon",
        to: { name: _ctx.toSnakeCase(_ctx.target) }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AddonIcon, {
            class: "icon-in-link",
            addon: _ctx.target
          }, null, 8, ["addon"]),
          (_ctx.text !== '')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.text ? _ctx.$t('previewLink.' + _ctx.text) : _ctx.$t('addons.' + _ctx.target)), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["to"]))
}