import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      density: "comfortable",
      variant: "plain",
      class: "social-button mr-2",
      size: "x-small"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("a", {
          href: "https://discord.gg/DR9cEDDNdN",
          target: "_blank",
          class: "social-icon discord-icon"
        }, null, -1)
      ])),
      _: 1
    }),
    _createVNode(_component_v_btn, {
      density: "comfortable",
      variant: "plain",
      class: "social-button",
      size: "x-small"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("a", {
          href: "https://github.com/Razdva122/avalon",
          target: "_blank",
          class: "social-icon github-icon"
        }, null, -1)
      ])),
      _: 1
    })
  ]))
}