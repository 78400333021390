import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    modelValue: _ctx.alert,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.alert) = $event)),
    rounded: "lg",
    color: "indigo-darken-3",
    icon: "$info",
    title: _ctx.$t('alert.title' + _ctx.id),
    density: "compact",
    closable: ""
  }, {
    close: _withCtx(() => [
      _createVNode(_component_v_btn, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.alert = false)),
        color: "white",
        size: "regular"
      }, {
        prepend: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "material-icons" }, " close ", -1)
        ])),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["modelValue", "title"]))
}